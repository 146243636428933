<template>
  <component
    :is="blok.sectionName ? 'section' : 'div'"
    :id="sectionId"
    v-editable="blok"
    :aria-label="blok.sectionName"
    class="component-grid relative !z-0 w-full px-4 [&_.component-grid]:!px-0"
    :style="`--no-of-items:${blok.body?.length || 0};--max-items:1;`"
  >
    <component
      :is="blok.gapConnectors === 'arrows' ? 'ol' : 'div'"
      class="container isolate mx-auto grid place-items-start"
      :style="`grid-template-columns: repeat(min(calc(var(--no-of-items) + ${blok.layout === 'small-large' ? 1 : 0}), var(--max-items)), minmax(0, 1fr));`"
      :class="[
        {
          'tablet:[--max-items:1]  desktop:[--max-items:4]': blok.layout === 'columns',
          'tablet:[--max-items:1] desktop:[--max-items:3]': blok.layout === 'grid' || blok.layout === 'small-large',
          'container-ext': blok.maxWidth === 'extended',
          'desktop:max-w-[48rem]': blok.maxWidth === 'small',
          'bg-primary dark:bg-secondary': blok.background === 'solid',
          'bg-white dark:bg-secondary': blok.background === 'solid-light',
          'bg-primary': blok.background === 'primary',
          'bg-secondary-dark': blok.background === 'secondary-dark',
          'bg-white dark:!border-secondary-dark': blok.background === 'white',
          'rounded border-2 border-current p-8': blok.background,
        },
        alignment,
        blok.gapConnectors ? 'gap-x-24 gap-y-24 tablet:gap-y-32 desktop:gap-y-[8rem]' : 'desktop:gap-y8 gap-x-12 gap-y-8 tablet:gap-y-8',
      ]"
    >
      <template v-if="blok.gapConnectors === 'arrows'">
        <li
          v-for="(childBlok, i) in blok.body"
          :key="childBlok._uid"
          class="with-arrows relative w-full list-none before:absolute before:left-1/2 before:top-0 before:h-16 before:w-16 before:-translate-x-1/2 before:-translate-y-[calc(100%+1rem)] before:rotate-90 before:place-self-center before:bg-no-repeat before:content-[''] first:before:content-none tablet:before:-top-4"
          :class="{
            'reversed-cell': blok.reverse,
            'desktop:col-span-2': blok.layout === 'small-large' && i % 2,
            'before:desktop:left-0 before:desktop:top-1/2 before:desktop:-translate-x-[calc(100%+1rem)] before:desktop:-translate-y-1/2 before:desktop:rotate-0':
              blok.layout !== 'rows',
          }"
        >
          <storyblok-component :blok="childBlok" :style="`--index: ${i}`" />
        </li>
      </template>
      <template v-else>
        <storyblok-component
          v-for="(childBlok, i) in blok.body"
          :key="childBlok._uid"
          :blok="childBlok"
          class="before:absolute before:left-1/2 before:top-0 before:h-16 before:w-16 before:-translate-x-1/2 before:-translate-y-[calc(100%+1rem)] before:place-self-center before:bg-no-repeat before:content-[''] first:before:content-none before:desktop:left-0 before:desktop:top-1/2 before:desktop:-translate-x-[calc(100%+1rem)] before:desktop:-translate-y-1/2"
          :class="{ 'reversed-cell': blok.reverse, 'desktop:col-span-2': blok.layout === 'small-large' && i % 2 }"
          :style="`--index: ${i}`"
        />
      </template>
    </component>
    <span v-if="isEditor && sectionId" class="absolute left-4 top-2 hidden max-w-60 truncate text-sm opacity-50 tablet:inline-block">#{{ sectionId }}</span>
  </component>
</template>

<script setup lang="ts">
import type { GridStoryblok } from "~/component-types-sb"
import { isEditorKey } from "~/injection-symbols"

defineSlots<{ default(): any }>()
const props = defineProps<{ blok: GridStoryblok }>()
const isEditor = inject(isEditorKey, false)

const sectionId = computed(() =>
  props.blok.sectionName
    ?.toLowerCase()
    .replaceAll("ä", "ae")
    .replaceAll("ö", "oe")
    .replaceAll("ü", "ue")
    .replaceAll("ß", "ss")
    .replaceAll(/[^a-z1-9-+ ]/g, "")
    .replaceAll(" ", "-"),
)

const alignment = computed(() => {
  switch (props.blok.alignment) {
    case "top":
      return "items-start"
    case "middle":
      return "items-center"
    case "bottom":
      return "items-end"
    case "fill":
      return "items-stretch h-full [&>li>*]:tablet:!h-full [&>div]:tablet:!h-full"
    default:
      return "items-center"
  }
})
</script>

<style scoped lang="scss">
.with-arrows::before {
  background-image: url("~/assets/icons/arrow-black.svg");
  background-size: 100%;

  @media (prefers-color-scheme: dark) {
    background-image: url("~/assets/icons/arrow-white.svg");
  }
}
</style>
